import * as yup from 'yup'

const passwordRegExp = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&_*])(?=.{8,})/

export const registrationSchema = (t: any) => {
  return yup.object().shape({
    email: yup.string().required(t('required')),
    password: yup
      .string()
      .required(t('required'))
      .matches(passwordRegExp, t('password_must_contain')),
    re_password: yup
      .string()
      .oneOf([yup.ref('password')], t('passwords_must_match')),
  })
}

export const newPasswordSchema = (t: any) => {
  return yup.object().shape({
    password: yup
      .string()
      .required(t('required'))
      .matches(passwordRegExp, t('password_must_contain')),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password')], t('passwords_must_match')),
  })
}

export const changePasswordValidationSchema = (t: any) => {
  return yup.object().shape({
    current_password: yup.string().required(t('required')),
    password: yup
      .string()
      .required(t('required'))
      .matches(passwordRegExp, t('password_must_contain')),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password')], t('passwords_must_match')),
  })
}
