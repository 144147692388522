import { parseCookies } from 'nookies'

import api from '@/lib/api'
import { IService } from '@/types/Basic'

import { API_ROUTE_SERVICES } from '../apiRoutes'

interface Response {
  results: IService[]
}

export const getServices = async (locale?: string) => {
  try {
    const cookies = parseCookies()
    const { NEXT_LOCALE } = cookies
    const lc = locale || NEXT_LOCALE || 'ru'

    const { data } = await api.get<Response>(API_ROUTE_SERVICES, {
      headers: {
        'Accept-Language': lc,
      },
    })

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
