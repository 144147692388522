import Caption from '@/components/ui/Typo/Caption'

interface Props {
  text: string
}

const InputError: React.FC<Props> = ({ text }) => {
  return (
    <Caption color="text-danger" className="mt-2">
      {text}
    </Caption>
  )
}

export default InputError
