import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'

import { getServices } from '@/api/services/getServices'
import ButtonText from '@/components/ui/Typo/ButtonText'
import Caption from '@/components/ui/Typo/Caption'
import Subtitle from '@/components/ui/Typo/Subtitle'
import { logo } from '@/res/images'
import { NAMESPACE_AUTH } from '@/res/namespaces'
import { ROUTE_SERVICES } from '@/res/routes'
import { IService } from '@/types/Basic'

import LanguageDropdown from './LanguageDropdown'

const AuthLeftSide: React.FC = () => {
  const { t, lang } = useTranslation(NAMESPACE_AUTH)

  const [services, setServices] = useState<IService[]>([])

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getServices(lang)

        setServices(res.results)
      } catch (err) {
        return err
      }
    }

    getData()
  }, [lang])

  return (
    <div className="laptop:w-1/2 flex flex-col items-center">
      <div className="max-w-[600px] text-center">
        <Link href="/">
          <div className="h-12 w-max mx-auto mb-4 cursor-pointer">
            <img src={logo.src} className="h-full" alt="Uztelecom logo" />
          </div>
        </Link>
        <Subtitle weight="font-medium" className="text-center mb-6">
          {t('one_account')}
        </Subtitle>
        <div className="px-5 pt-7 pb-3 border-b border-gray-200 flex justify-evenly">
          {services.map(({ icon, id, name, slug, is_on_premise, url }) => {
            return is_on_premise ? (
              <Link href={`${ROUTE_SERVICES}/${slug}`} key={id}>
                <a>
                  <div className="flex justify-center items-center flex-col p-2 rounded-md transition-colors cursor-pointer">
                    <div className="w-12 h-12 flex items-center justify-center">
                      <img
                        src={icon}
                        alt={name}
                        className="w-full h-full rounded"
                      />
                    </div>
                    <ButtonText
                      color="text-dark"
                      size="small"
                      className="mt-2 text-center"
                    >
                      {name}
                    </ButtonText>
                  </div>
                </a>
              </Link>
            ) : (
              <a href={url} key={id}>
                <div className="flex justify-center items-center flex-col p-2 rounded-md transition-colors cursor-pointer">
                  <div className="w-12 h-12 flex items-center justify-center">
                    <img
                      src={icon}
                      alt={name}
                      className="w-full h-full rounded"
                    />
                  </div>
                  <ButtonText
                    color="text-dark"
                    size="small"
                    className="mt-2 text-center"
                  >
                    {name}
                  </ButtonText>
                </div>
              </a>
            )
          })}
        </div>
        <Caption color="text-gray1" className="mt-4">
          {t('description', { appName: 'Uztelecom' })}
        </Caption>
        <div className="flex items-center mt-8 justify-evenly">
          <LanguageDropdown />
          <a
            href="https://uztelecom.uz/ru/o-kompanii-1/publichnaya-oferta-1"
            target="_blank"
            rel="noreferrer"
          >
            <ButtonText color="text-dark" className="underline" size="small">
              {t('public_offer')}
            </ButtonText>
          </a>
          <ButtonText color="text-dark" size="small">
            © {new Date().getFullYear()} Uztelecom
          </ButtonText>
        </div>
      </div>
    </div>
  )
}

export default AuthLeftSide
