import { API_ROUTE_REGISTER_USER } from '@/api/apiRoutes'
import api from '@/lib/api'
import { RegisterResponse } from '@/types/Auth'

interface Body {
  email: string
  password: string
  password_confirmation: string
}

export const registerUser = async (body: Body) => {
  try {
    const { data } = await api.post<RegisterResponse>(
      API_ROUTE_REGISTER_USER,
      body
    )

    return data
  } catch (err) {
    return Promise.reject(err)
  }
}
