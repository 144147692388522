const Spinner = ({
  sizeClass = 'h-6 w-6',
  color = 'border-white',
}: {
  sizeClass?: string
  color?: string
}) => (
  <div
    className={`${sizeClass} animate-spin rounded-full border-2 border-r-[transparent] ${color}`}
  />
)
export default Spinner
