import { InputHTMLAttributes, ReactNode } from 'react'
import { FieldError, RegisterOptions, useFormContext } from 'react-hook-form'

import TextField from './TextField'

interface Props {
  name: string
  label: string
  registerOptions?: RegisterOptions
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  customComponent?: ReactNode
}

const FormTextField = ({
  name,
  registerOptions,
  inputProps,
  label,
  customComponent,
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const properties = register(name, registerOptions)
  const error = errors[name]

  return (
    <TextField
      inputProps={{
        ...properties,
        ...inputProps,
      }}
      error={error as FieldError}
      label={label}
      customComponent={customComponent || undefined}
    />
  )
}

export default FormTextField
