import { yupResolver } from '@hookform/resolvers/yup'
import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { getApiTokenLocal } from '@/api/auth/getApiTokenLocal'
import { registerUser } from '@/api/auth/registerUser'
import { IconEye, IconEyeClosed } from '@/res/icons'
import { NAMESPACE_ERRORS } from '@/res/namespaces'
import { ROUTE_PROFILE } from '@/res/routes'
import { registrationSchema } from '@/res/validationSchemas'
import { LoginForm, RegisterForm, TAuthForm } from '@/types/Auth'

interface Props {
  type: TAuthForm
}

type TFormData = LoginForm & RegisterForm

export const useAuthForm = ({ type }: Props) => {
  const [isError, setIsError] = useState(false)
  const { t: te } = useTranslation(NAMESPACE_ERRORS)
  const [pending, setPending] = useState(false)
  const [isShowing, setIsShowing] = useState(false)

  const defaultValues: TFormData = {
    email: '',
    password: '',
    re_password: '',
  }
  const methods = useForm<TFormData>({
    defaultValues,
    resolver:
      type === 'register' ? yupResolver(registrationSchema(te)) : undefined,
  })
  const { handleSubmit, setError } = methods
  const router = useRouter()

  const togglePasswordShowing = () => {
    setIsShowing((prevState) => !prevState)
  }

  const passwordShowing = (
    <div className="absolute right-4 top-3">
      {isShowing ? (
        <IconEyeClosed
          className="cursor-pointer text-primary"
          onClick={togglePasswordShowing}
        />
      ) : (
        <IconEye
          className="cursor-pointer text-primary"
          onClick={togglePasswordShowing}
        />
      )}
    </div>
  )

  const setCredentialsErrors = () => {
    setIsError(true)
    setError('email', {})
    setError('password', {})
  }

  const onRegister = async (data: TFormData) => {
    try {
      const res = await registerUser({
        email: data.email,
        password: data.password,
        password_confirmation: data.password,
      })

      const loginRes = await signIn('credentials', {
        user: JSON.stringify(res),
      })

      if (loginRes?.ok) {
        router.push(ROUTE_PROFILE)
      }
    } catch (err: any) {
      const errors = err.response?.data?.errors || {}

      Object.keys(errors).forEach((fieldErr) => {
        setError(fieldErr as keyof RegisterForm, {
          message: errors[fieldErr],
        })
      })
    }
  }

  const onLogin = async (data: TFormData) => {
    try {
      const from = router.query.from as string
      const res = await signIn('credentials', {
        password: data.password,
        email: data.email,
        redirect: false,
      })

      if (res?.ok) {
        router.push(from ? from : ROUTE_PROFILE)
      } else {
        setCredentialsErrors()
      }
    } catch {
      setCredentialsErrors()
    }
  }

  const onTelegramLogin = async ({ email, password }: TFormData) => {
    try {
      const { token } = await getApiTokenLocal({ email, password })

      if (!token) {
        setCredentialsErrors()
        return
      }

      window.location.href = `
      tg://resolve?domain=${process.env.NEXT_PUBLIC_UZDC_TELEGRAM_BOT}&start=${token}`
    } catch (err: any) {
      setCredentialsErrors()
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    if (isError) {
      setIsError(false)
    }
    setPending(true)
    switch (type) {
      case 'login':
        onLogin(data)
        break
      case 'register':
        onRegister(data)
        break
      case 'telegram_login':
        onTelegramLogin(data)
        break
      default:
        return
    }
    setPending(false)
  })

  return { onSubmit, pending, passwordShowing, isError, methods, isShowing }
}
