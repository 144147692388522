import axios, { AxiosError } from 'axios'

import { API_ROUTE_TOKEN_LOGIN_LOCAL } from '../apiRoutes'

interface Body {
  email: string
  password: string
}

interface Response {
  token: string
}

export const getApiTokenLocal = async ({ email, password }: Body) => {
  try {
    const { data } = await axios.post<Response>(API_ROUTE_TOKEN_LOGIN_LOCAL, {
      email,
      password,
    })
    return data
  } catch (err) {
    return Promise.reject(err as AxiosError)
  }
}
