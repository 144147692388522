import { ITypo } from '@/types/Typo'

export interface ButtonTextProps extends ITypo {
  size: 'small' | 'big'
}

const ButtonText: React.FC<ButtonTextProps> = ({
  children,
  className = '',
  color = 'text-white',
  weight = 'font-medium',
  size,
}) => {
  return (
    <p
      className={`${className} ${color} ${weight} leading-5 ${
        size === 'small'
          ? 'hr:text-base desktop:text-[16px] leading-5 text-[15px]'
          : 'hr:text-md desktop:text-[18px] desktop:leading-5 text-[17px] tablet:leading-4 leading-5'
      } `}
    >
      {children}
    </p>
  )
}

export default ButtonText
