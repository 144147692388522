import { ITypo } from '@/types/Typo'

const Subtitle: React.FC<ITypo> = ({
  className = ' ',
  color = 'textPrimary',
  weight = 'font-normal',
  children,
}) => {
  return (
    <p
      className={`hr:text-2xl desktop:text-[22px] desktop:leading-7 laptop:text-[20px] laptop:leading-6 tablet:text-[19px] tablet:leading-6 text-[18px] leading-6 ${className} ${color} ${weight}`}
    >
      {children}
    </p>
  )
}

export default Subtitle
