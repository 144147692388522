import { InputHTMLAttributes } from 'react'
import { FieldError } from 'react-hook-form'

import InputError from './InputError'

interface Props {
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  label: string
  className?: string
  wrapperClassName?: string
  labelClassName?: string
  error?: FieldError
  customComponent?: any
}

const TextField: React.FC<Props> = ({
  inputProps,
  label,
  className = '',
  wrapperClassName = '',
  labelClassName = '',
  error,
  customComponent,
}) => {
  return (
    <div className={`relative z-0 w-full mb-6 group ${wrapperClassName}`}>
      <input
        {...inputProps}
        placeholder={inputProps?.placeholder || ' '}
        className={`block py-2.5 px-0 w-full text-base font-medium text-black bg-transparent border-0 border-b-2 ${
          error ? 'border-danger' : 'border-gray2 focus:border-primary'
        } appearance-none focus:outline-none focus:ring-0 peer ${className}`}
      />
      {customComponent}

      <label
        htmlFor={inputProps?.name}
        className={`peer-focus:font-medium absolute text-tiny text-dark  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-dark peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${labelClassName}`}
      >
        {label}
      </label>
      {error?.message ? <InputError text={error.message} /> : null}
    </div>
  )
}

export default TextField
