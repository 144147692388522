import Link from 'next/link'
import { useRouter } from 'next/router'
import { signIn, useSession } from 'next-auth/react'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'

import Spinner from '@/components/ui/Spinner'
import ButtonText from '@/components/ui/Typo/ButtonText'
import Caption from '@/components/ui/Typo/Caption'
import { useAuthForm } from '@/hooks/useAuthForm'
import { APP_NAME } from '@/res/consts'
import { IconGoogle, IconYandex } from '@/res/icons'
import { NAMESPACE_AUTH } from '@/res/namespaces'
import {
  ROUTE_LOGIN,
  ROUTE_PRIVACY_POLICY,
  ROUTE_PROFILE,
  ROUTE_REGISTRATION,
  ROUTE_RESET_PASSWORD,
} from '@/res/routes'
import { TAuthForm } from '@/types/Auth'

import FormTextField from '../ui/FormTextField'

interface Props {
  type: TAuthForm
}

const AuthForm: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation(NAMESPACE_AUTH)
  const { isError, onSubmit, passwordShowing, pending, methods, isShowing } =
    useAuthForm({
      type,
    })
  const isRegistering = type === 'register'
  const { data } = useSession()
  const router = useRouter()

  //eslint-disable-next-line
  console.log(data)

  useEffect(() => {
    if (data?.user?.accessToken) {
      setTimeout(() => {
        router.push(ROUTE_PROFILE)
      }, 200)
    }
  }, [router, data?.user?.accessToken])

  return (
    <div className="flex justify-center laptop:w-1/2">
      <div className="small:w-[420px] min-w-[340px] py-8 rounded small:shadow-[0_15px_30px_0_rgb(0_0_0_/10%)]">
        <ButtonText
          size="big"
          color="text-dark"
          className={`text-center ${isError ? 'mb-6' : 'mb-10'}`}
        >
          {type === 'register' ? t('register') : t('sign_in')}
        </ButtonText>

        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className="w-3/4 mx-auto">
            {isError ? (
              <Caption
                color="text-danger"
                className="mx-auto mt-3 mb-10 text-center"
              >
                {t('login_error')}
              </Caption>
            ) : null}
            <div>
              <FormTextField
                inputProps={{
                  autoComplete: 'off',
                  required: true,
                }}
                name="email"
                label="Email"
              />
              <FormTextField
                inputProps={{
                  autoComplete: 'off',
                  type: isShowing ? 'text' : 'password',
                  required: true,
                }}
                name="password"
                label={t('password')}
                customComponent={isRegistering ? passwordShowing : undefined}
              />
              {isRegistering ? (
                <>
                  <FormTextField
                    inputProps={{
                      autoComplete: 'off',
                      type: isShowing ? 'text' : 'password',
                      required: true,
                    }}
                    name="re_password"
                    label={t('re_password')}
                  />
                  <Caption
                    color="text-gray1"
                    weight="font-medium"
                    className="mb-6 text-center"
                  >
                    <Trans
                      i18nKey="register_text"
                      ns={NAMESPACE_AUTH}
                      components={[
                        //eslint-disable-next-line
                        <a
                          href="https://uztelecom.uz/ru/o-kompanii-1/publichnaya-oferta-1"
                          target="_blank"
                          className="underline"
                          key={0}
                        />,
                        //eslint-disable-next-line
                        <a
                          href={ROUTE_PRIVACY_POLICY}
                          className="underline"
                          key={1}
                        />,
                      ]}
                    />
                  </Caption>
                </>
              ) : null}

              {!isRegistering ? (
                <Caption
                  color="text-primary"
                  className="my-3 text-center small:text-right"
                >
                  <Link href={ROUTE_RESET_PASSWORD}>
                    <a className="font-medium underline text-dark">
                      {t('forgot_password')}
                    </a>
                  </Link>
                </Caption>
              ) : null}

              <button
                type="submit"
                className="w-full py-3 transition-all rounded-lg bg-primary hover:opacity-90 active:scale-x-95"
              >
                {pending ? (
                  <span className="flex justify-center w-full">
                    <Spinner />
                  </span>
                ) : (
                  <ButtonText size="small">
                    {isRegistering
                      ? t('register_btn_text')
                      : t('login_btn_text')}
                  </ButtonText>
                )}
              </button>

              <div className="flex justify-center pt-5 mt-5 border-t gap-x-3">
                <button
                  onClick={() => {
                    signIn('google')
                  }}
                  type="button"
                  className="flex items-center justify-center px-4 py-2 text-black transition-all border border-gray-200 rounded-lg hover:bg-primary hover:text-white hover:opacity-90 active:scale-x-95"
                >
                  <IconGoogle className="w-9 h-9" />
                </button>
                <button
                  onClick={() => {
                    signIn('yandex')
                  }}
                  type="button"
                  className="flex items-center justify-center px-4 py-2 text-black transition-all border border-gray-200 rounded-lg hover:bg-primary hover:text-white hover:opacity-90 active:scale-x-95"
                >
                  <IconYandex className="w-9 h-9" />
                </button>
              </div>
              <Caption color="text-primary" className="mt-5 text-center">
                {isRegistering
                  ? t('already_have_an_account', { appName: APP_NAME })
                  : t('dont_have_an_account', { appName: APP_NAME })}
                <Link href={isRegistering ? ROUTE_LOGIN : ROUTE_REGISTRATION}>
                  <a className="ml-2 font-medium underline text-dark">
                    {isRegistering ? t('login') : t('register')}
                  </a>
                </Link>
              </Caption>
              {/* <button onClick={() => signIn('keycloak')}>
                login keykcloak
              </button> */}
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

export default AuthForm
